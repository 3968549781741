.profile__add-button {
  background-image: url(../../../images/add-icon.svg);
  background-color: #000;
  background-position: center;
  background-repeat: no-repeat;
  border: 2px solid #fff;
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  height: 50px;
  transition: opacity 0.2s ease;
}

.profile__add-button:hover {
  border: 2px solid rgba(255, 255, 255, 0.6);
  opacity: 0.6;
}

@media screen and (min-width: 550px) {
  .profile__add-button {
    width: 120px;
    height: 50px;
    margin-top: 30px;
  }
}

@media screen and (min-width: 768px) {
  .profile__add-button {
    margin-top: 0;
  }
}

@media screen and (min-width: 1024px) {
  .profile__add-button {
    width: 150px;
  }
}
