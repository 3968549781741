.header__link {
  color: white;
  text-decoration: none;
  font-size: 18px;
  line-height: 1.22;
  font-weight: 400;
  transition: opacity 0.3s ease;
}
.header__link:hover {
  opacity: 0.6;
}

@media screen and (max-width: 610px) {
  .header__link {
    font-size: 14px;
  }
}