.card__image {
  width: 100%;
  height: 282px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border: none;
  outline: unset;
  object-fit: cover;
  display: block;
}
