.profile__user {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
}

@media screen and (min-width: 550px) {
  .profile__user {
    font-size: 32px;
    line-height: 1.2;
  }
}

@media screen and (min-width: 768px) {
  .profile__user {
    font-size: 42px;
    line-height: 1.2;
  }
}
