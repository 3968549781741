.popup__title {
  font-size: 18px;
  font-weight: 900;
  line-height: 1.2;
  padding-bottom: 10px;
}

@media screen and (min-width: 550px) {
  .popup__title {
    font-size: 24px;
    padding-bottom: 10px;
  }
}
