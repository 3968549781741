.profile__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 550px) {
  .profile__container {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
  }
}
