.cards__container {
  display: grid;
  gap: 20px 20px;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  margin: 0 auto;
  max-width: 880px;
  padding: 0 20px 0px;
}

@media screen and (min-width: 1024px) {
  .cards__container {
    padding: 0 0 50px;
  }
}
