.profile__edit-button {
  background-image: url(../../../images/edit-icon.svg);
  background-size: 10px 10px;
  background-repeat: no-repeat;
  background-position: center;
  width: 18px;
  height: 18px;
  background-color: black;
  align-self: center;
  padding: inherit;
  border: 1px solid white;
  cursor: pointer;
  margin-left: 10px;
  transition: opacity 0.2s ease;
}

.profile__edit-button:hover {
  background-image: url(../../../images/edit-icon.svg);
  border: 1px solid rgba(255, 255, 255);
  opacity: 0.6;
}

@media screen and (min-width: 550px) {
  .profile__edit-button {
    margin-left: 18px;
  }
}
