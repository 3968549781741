.popup__container {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 25px rgb(0 0 0 / 15%);
  box-sizing: border-box;
  color: #000;
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin: 0 20px;
  position: relative;
}

@media screen and (min-width: 550px) {
  .popup__container {
    width: 430px;
    padding: 36px;
  }
}
