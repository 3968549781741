.profile__profession {
  font-size: 14px;
  font-weight: 1.2;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 14px 0 36px;
}

@media screen and (min-width: 550px) {
  .profile__profession {
    text-align: left;
    margin-top: 10px;
    padding: 0;
  }
}
