.form__input {
  background-color: transparent;
  border: none;
  color: #cccccc;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 13px;
  outline: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.21;
}

@media screen and (max-width: 610px) {
  .form__input {
    padding-bottom: 9px;
  }
}
