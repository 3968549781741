.profile__avatar-overlay {
  background-image: url(../../../images/pencil-vector.svg);
  background-color: rgba(0, 0, 0, 0.8);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 20%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  opacity: 0;
  position: absolute;
  transform: scale(1, 1);
  transition: opacity 0.3s ease, transform 0.3s ease;
  width: 100%;
  z-index: 1;
}

.profile__avatar-overlay:hover {
  opacity: 1;
  transform: scale(1);
}
