.header {
  width: 100%;
  height: 75px;
  max-width: 880px;
  margin: 0 auto 0;
  border-bottom: 1px solid #545454;
  padding: 0 19px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  position: relative;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 480px) {
  .header {
    max-height: 56px;
  }
}
