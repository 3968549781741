.footer__copyright {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #545454;
}

@media screen and (max-width: 610px) {
  .footer__copyright {
    font-size: 14px;
  }
}